        .formCheckLabel {
            position: relative;
            top: -2px;
        }

        .formCheck {
            display: block;
            min-height: 1.5rem;
            padding-left: 1.5em;
            margin-bottom: 0.125rem;
        }

        .formCheck .formCheckInput {
            float: left;
            margin-left: -1.5em;
        }

        .formCheckInput {
            width: 1em;
            height: 1em;
            margin-top: 0.25em;
            vertical-align: top;
            background-color: var(--mainBg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            border: 1px solid rgba(var(--mainFgRgb), 0.25);
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .formCheckInput[type="checkbox"] {
            border-radius: 0.25em;
        }

        .formCheckInput[type="radio"] {
            border-radius: 50%;
        }

        .formCheckInput:active {
            filter: brightness(90%);
        }

        .formCheckInput:focus {
            border-color: var(--topBrand);
            outline: 0;
            box-shadow: 0 0 0 0.15rem var(--gray300);
        }

        .formCheckInput:checked {
            background-color: var(--popBrand);
            border-color: var(--popBrand);
        }

        .formCheckInput:checked[type="checkbox"] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }

        .formCheckInput:checked[type="radio"] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
        }

        .formCheckInput[type="checkbox"]:indeterminate {
            background-color: var(--popBrand);
            border-color: var(--popBrand);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
        }

        .formCheckInput:disabled {
            pointer-events: none;
            filter: none;
            opacity: 0.5;
        }

        .formCheckInput[disabled]~.formCheckLabel,
        .formCheckInput:disabled~.formCheckLabel {
            opacity: 0.5;
        }