@use "breakpoints.scss";

.star {
    display: inline-block;
    color: #ccc;
    font-size: 120%;
    transition: 0.1s all ease-in-out;
    transform: scale(0.8);

    &.readOnly {
        transform: scale(1)
    }
}

.star:hover,
.star.semiActive {
    color: #ffd73e;
    transform: scale(1);

    &.readOnly {
        color: #ccc;
    }
}

.star:focus {
    outline: none;
}

.star.active {
    color: #ff9800;
    transform: scale(1);

    &:hover {
        color: #ff9800;
    }
}

/* Applies to small mobiles (-576px) */
@media screen and (breakpoints.$smDown) {
    .star {

        font-size: 100%;
    }
}

.ratingStars {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &.readOnly {
        cursor: default;
    }
}