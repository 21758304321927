.arrow {
  transition: transform 0.25s ease-in-out;
  display: flex;
  margin-left: 4px;
  align-items: center;

  &.open {


    svg {
      transition: transform 0.25s linear;
      transform: rotateX(180deg);
      transform-origin: center;
    }
  }

  &.close {


    svg {
      transition: transform 0.25s linear;
      transform: rotateX(0deg);
      transform-origin: center;
    }
  }
}